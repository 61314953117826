import React from 'react';
import { RichText } from 'prismic-reactjs';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import carouselStyle from 'assets/jss/material-kit-react/views/homePageSections/partnersStyle';

const SectionPartners = ({ classes, title, partners }) => {
  const settings = {
    className: 'center',
    arrows: false,
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    autoplay: true,
    centerPadding: '60px',
  };

  return partners ? (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12}>
          <h2 className={classes.title}>{RichText.asText(title.raw)}</h2>
        </GridItem>
      </GridContainer>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
            {partners.length > 1 ? (
              <Carousel {...settings}>
                {partners.map(document => (
                  <div>
                    <a
                      href={RichText.asText(document.link.raw)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={document.logo.url} alt="First slide" className="slick-image" />
                    </a>
                  </div>
                ))}
              </Carousel>
            ) : null}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  ) : null;
};

export default withStyles(carouselStyle)(SectionPartners);
