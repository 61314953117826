import { container, title } from '../../material-kit-react';

const aboutPageStyle = theme => ({
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...container,
    textAlign: 'center',
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: '#FFFFFF',
    textDecoration: 'none',
    textShadow: '2px 0 10px black',
    fontFamily: 'Great Vibes',
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px auto 0',
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('xs')]: {
      margin: '-40px 0px 0px',
    },
  },
  mainContainer: {
    padding: '20px 0',
    zIndex: '12',
    color: '#333',
    ...container,
    textAlign: 'center',
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  text: {
    fontSize: '1rem',
    '& p': {
      fontSize: '1rem',
    },
  },
  glow: {
    display: 'inline-block',
    width: 0,
    height: '34px',
    position: 'relative',
    zIndex: 2,
    overflow: 'visible',
    wordSpacing: 0,
    textAlign: 'left',
    cursor: 'pointer',
    animation: '$pulse 1s infinite',
  },
  glowingItem: {
    position: 'absolute',
    top: '45px',
    whiteSpace: 'nowrap',
    padding: '0 5px 0 6px',
    fontFamily: 'Montserrat, Helvetica, sans-serif',
    color: '#ffffff',
    fontSize: '15px',
    lineHeight: '20px',
    textDecoration: 'none',
    backgroundColor: '#819E84',
    textShadow: 'none',
    filter: 'none',
    boxShadow: '-1px 0px 1px 0px #000000',
    '&:hover': {
      color: '#819E84',
      backgroundColor: '#ffffff',
    },
  },
  bar: {
    display: 'inline-block',
    position: 'absolute',
    top: '15px',
    width: '1px',
    height: '30px',
    boxShadow: '-1px 0px 1px 0px #000000',
    backgroundColor: '#ffffff',
  },
  '@keyframes pulse': {
    from: {},
    to: {
      transform: 'scale3d(1, 1, 1)',
    },
    '50%': {
      transform: 'scale3d(1.05, 1.05, 1.05)',
    },
  },
});

export default aboutPageStyle;
