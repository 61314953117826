import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import LazyLoad from 'react-lazyload';

// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Layout from 'components/Layout';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Parallax from 'components/Parallax/Parallax';
import SEO from 'components/SEO';
import Spinner from 'components/Spinner/Spinner';

// sections for this page
import SectionPartners from 'components/AboutPage/SectionPartners';

import backgroundImage from 'assets/img/statue-notre-dame.jpg';
import aboutPageStyle from 'assets/jss/material-kit-react/views/aboutPage';

const About = ({ classes, data }) => {
  if (!data) return null;

  const content = data.prismicAboutPage.data;
  const { lang } = data.prismicAboutPage;
  const alternateLanguages = data.prismicAboutPage.alternate_languages;

  const activeDocMeta = { lang, alternateLanguages };

  const title = content.headline
    ? RichText.asText(content.headline.raw)
    : 'Please add some content';

  const description = content.description
    ? RichText.asText(content.description.raw)
    : 'Please add some content';

  const heroImage = content.hero_image ? content.hero_image.url : backgroundImage;
  const partnersSection = data.prismicHomepage.data;
  const sectionTitle = partnersSection.partners_section_title;
  const { partners, tours } = partnersSection;

  const menu = data.prismicMenu.data;
  if (!menu) return null;

  return (
    <Layout activeDocMeta={activeDocMeta} menu={menu} tours={tours}>
      <SEO title="Learn more about us | Servitours" />
      <Parallax filter image={heroImage}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>{title}</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.mainContainer}>
          <GridContainer justifyContent="center">
            <GridItem xs={12}>
              <h4 className={classes.text}>{description}</h4>
              <br />
            </GridItem>
          </GridContainer>
          <LazyLoad height={200} once offset={[-200, 0]} placeholder={<Spinner />}>
            <SectionPartners title={sectionTitle} partners={partners} />
          </LazyLoad>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AboutPageQuery($lang: String = "fr-fr") {
    prismicAboutPage(lang: { eq: $lang }) {
      alternate_languages {
        lang
        type
        uid
      }
      data {
        description {
          raw
        }
        headline {
          raw
        }
        hero_image {
          url
        }
      }
      lang
    }
    prismicHomepage(lang: { eq: $lang }) {
      data {
        tours {
          destination {
            document {
              ... on PrismicDestination {
                id
                lang
                type
                uid
                url
                data {
                  name {
                    raw
                  }
                }
              }
            }
          }
          message {
            raw
          }
        }
        partners {
          link {
            raw
          }
          logo {
            url
          }
        }
        partners_section_title {
          raw
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      data {
        links {
          label {
            raw
          }
          link {
            lang
            type
            url
          }
        }
      }
    }
  }
`;

export default withStyles(aboutPageStyle)(About);
